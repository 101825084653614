<template>
  <div>
 
  <div class="heade">
    <div style="height:50px;"><img src="../assets/logo002.png" width="60px" style="float:left;margin-right:10px;margin-top:7px" />
      <span style="float:left;font-weight: bold;margin-top:-4px;"><div style="margin-top:10px;font-size:16px;">全国高校计算机系统能力培养</div></span>
    </div>
    <div>
      <a-menu v-model="current" mode="horizontal" v-if="widthValue" >
        <a-menu-item key="0" @click="menuFun('./', '0', '')">
          首页
        </a-menu-item>
        <a-menu-item
          :key="index + 1 + ''"
          @click="menuFun('./temp', index + 1, item.title)"
          v-for="(item, index) in aryData"
        >
          {{ item.title }}</a-menu-item
        >
      </a-menu>

        <a-icon type="menu" @click="tabFun"  v-else/>
    
   

     
    </div>

  </div>
  <div v-if="menu"  style="position: absolute;z-index: 999;border-bottom:1px solid #eee;">
        <a-menu v-model="current" mode="inline">
        <a-menu-item key="0" @click="menuFun('./', '0', '')">
          首页
        </a-menu-item>
        <a-menu-item
          :key="index + 1 + ''"
          @click="menuFun('./temp', index + 1, item.title)"
          v-for="(item, index) in aryData"
        >
          {{ item.title }}</a-menu-item
        >
      </a-menu>
      </div>
  </div>
</template>

<script>
import car from "../components/car.vue";
export default {
  name: "heade",
  data() {
    return {
      curData: [],
      aryData: [],
      current: ["0"],
      title:'',
      widthValue:true,
      menu:false,
      mode:'horizontal'
    };
  },
  props: {
    // current:Array,
  },
  // watch:{
  //   current(o,n){
  //     console.log(o,n)
  //   }
  // },
  components: {
    car,
  },
  watch:{
    title(o,n){
      console.log(o,n)

    }
  },
  mounted() {
    if(document.body.clientWidth<1240){
      this.widthValue = false;
      this.mode='inline'
    }
    else {
      this.widthValue = true;
      this.mode = 'horizontal'
    }
    // window.resizeTo(()=>{
    //   if(document.body.clientWidth<1240){
    //   this.widthValue = false;
    //   this.mode='inline'
    // }
    // else {
    //   this.widthValue = true;
    //   this.mode = 'horizontal'
    // }
    // })
    // this.aryData = JSON.parse(window.localStorage.getItem("xx-anjing-aryData"));

    //  if( ){
    //   this.current[0] = '4'
    //  }
    //  console.log( this.current[0]  )
    debugger
    console.log(this.current);
    this.current = [];
    debugger;
    if (this.$route.query.id) {
      this.current.push(this.$route.query.id);
    }else if((window.location.href).indexOf('about?id')!=-1){
      this.current.push(window.location.href.split('about?id=')[1]);
    } 
    else if((window.location.href).indexOf('common?id')!=-1){
      this.current.push(window.location.href.split('common?id=')[1]);
    }else {
      this.current.push("0");
    }
this.headerFun()
    console.log(this.current);
    // window.localStorage.setItem('xtnlds-anjing','系统能力大赛')
    // if(this.current[0] == 1){
    //     window.localStorage.setItem('xtnlds-anjing','系统能力大赛')
    // }
    // else   if(this.current[0]  == 2){
    //     window.localStorage.setItem('xtnlds-anjing','系统能力培养会')
    // }
    // else   if(this.current[0]  == 3){
    //     window.localStorage.setItem('xtnlds-anjing','组织机构')
    // }
    // else   if(this.current[0]  == 4){
    //     window.localStorage.setItem('xtnlds-anjing','新闻活动')
    // }
  },
  methods: {
    tabFun(){
      this.menu = !this.menu;
    },

    headerFun(){
      let data = {
        op: "modules",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.aryData = response.data.data;
            // 把导航保存下来
            // window.localStorage.setItem('xx-anjing-aryData',JSON.stringify(_this.listAry));
            // _this.initContent();
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    menuFun(URL, key, title) {
      this.menu= false
      debugger;
      // this.current = key;
      window.localStorage.setItem("xtnlds-anjing-index", key);
      window.localStorage.setItem("xtnlds-anjing-ej-tab",'')
      // this.$emit('currentTabFun',key)
      // if(key == 1){
      //       window.localStorage.setItem('xtnlds-anjing','系统能力大赛')
      //   }
      //   else   if(key == 2){
      //       window.localStorage.setItem('xtnlds-anjing','系统能力培养会')
      //   }
      //   else   if(key == 3){
      //       window.localStorage.setItem('xtnlds-anjing','组织机构')
      //   }
      //   else   if(key == 4){
      //       window.localStorage.setItem('xtnlds-anjing','新闻活动')
      //   }
      console.log(key)
   
      window.localStorage.setItem("xtnlds-anjing", title);
      if(title){
        this.$router.push({ path: URL, query: { id: key } });
      }
      else {
        this.$router.push({ path: URL});
      }


    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 2px;
  font-weight: bold;
}
a {
  color: #42b983;
}
.heade {
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  padding: 15px 2% 0;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
}
.ant-menu-horizontal {
  border: none;
  margin-top: -3px;
}
.ant-menu-horizontal{
  margin-top:-13px;
  line-height: 55px;
}

</style>
