<template>
  <div>
    <!-- <heade :aryData="listAry"></heade> -->
    <div style="min-height: calc(100vh - 150px)">
      <div>
        <!-- <a-carousel autoplay>
          <div>
            <a-avatar
              src="../assets/banner-1.png"
            />
          </div>
          <div>
            <a-avatar
              src="../assets/banner-1.png"
            />
          </div>
        </a-carousel> -->
        <a-carousel autoplay>
    <div><img src="../assets/banner-1.png"/></div>
    <div><img src="../assets/Compiler_banner.png"/></div>
    <div><img src="../assets/DB-banner.png"/></div>
  
  </a-carousel>
      </div>
      <div class='right-fixed' v-if="closeValue">
        <a-card title="申请与申报" style="width: 400px" >
      <!-- <a slot="extra" href="#">更多</a> -->
      <a-icon  @click='closeFun' type="close" slot="extra" style="color:#fff;width:30px;height:30px;font-size:20px"/>
       <!-- <div style="width:20px;height:20px;" slot="extra"><i class="el-icon-edit"></i></div> -->
      <p v-for="(item,index) in sblist " :key="index"><a :href="item.value" style="width:100%;height:100%">{{ item.key }} </a><a-icon type="right" /></p>

   
      
      
    </a-card>
      </div>
      <div class="a">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            margin-top: 10px;
            margin: 10px 5%;
            flex-wrap: wrap;
          "
        >
          <a-card
            v-for="(item, index) in listAry"
            :key="index"
            :title="item.title"
            style="
              width: 49%;
              text-align: left;
              margin-right: 1%;
              margin-bottom: 10px;
            
            "
          >
            <a
              slot="extra"
              href="javascript:;"
              @click="goToFun('./temp', index + 1, item.title)"
              >更多</a
            >
  
            <div v-for="(p, iii) in listCon" :key="iii">
              <p v-if="p.title == item.title">
             
                <a
                  v-for="(t, i) in p.titleAry"
                  :key="i"
                  @click="toType(t.sort, item.title, index + 1)"
                  >{{ t.title }}
                  <a-divider
                    type="vertical"
                    v-if="p.titleAry.length - 1 != i"
                /></a>
                <!-- <a>大赛组织 <a-divider type="vertical" /> </a>
              <a>大赛英雄榜 <a-divider type="vertical" /> </a>
              <a>大赛资源 <a-divider type="vertical" /> </a>
              <a>大赛官网 </a> -->
              </p>
              <ul class="ul-li"  v-if="p.title == item.title">
            
                <li v-for="(b, ii) in p.list" :key="ii" @click="goUrl(b.url,b.sort, index + 1, item.title)" style="color:rgb(2 138 186);    cursor: pointer;">{{ b.title }}</li>
                <!-- <li>2022全国大学生计算机系统能力大赛 编译系统设计赛章程</li>
              <li>
                2021全国大学生计算机系统能力大赛操作系统设计大赛
                决赛入围名单暨优胜奖获得者
              </li>
              <li>2021年全国大学生计算机系统能力培养大赛 报名启动</li>
              <li>百舸争流，鹰击长空，第四届“龙芯杯”圆满落幕</li> -->
              </ul>
            </div>
          </a-card>
          <!-- <a-card title="系统能力培养会" style="width: 50%; text-align: left;margin-bottom:10px;">
            <a slot="extra" href="javascript:;" @click="goToFun('./pyh')"
              >更多</a
            >
            <p @click="goToFun('./pyh')">
              <a>项目介绍</a> <a-divider type="vertical" /><a>专家组成员</a>
              <a-divider type="vertical" /> <a>试点院校</a>
              <a-divider type="vertical" /> <a>新工科项目</a>
              <a-divider type="vertical" /> <a>大事记</a>
            </p>
            <ul>
              <li>
                第二届“龙芯杯”全国大学生计算机系统能力培养大赛总决赛胜利闭幕
              </li>
              <li>2018中国核心技术创新发展峰会于南京成功举办</li>
              <li>2018年“计算机体系结构”课程教导班火爆开班</li>
            </ul>
            <div>
              <div>
                <a-avatar
                  :size="64"
                  src="https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570285826696065.jpg"
                  style="margin-left: 25px"
                />
                <a-avatar
                  :size="64"
                  src="https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286497792714.jpg"
                  style="margin-left: 25px"
                />
                <a-avatar
                  :size="64"
                  src="https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286220222552.jpg"
                  style="margin-left: 25px"
                />

                <a-divider
                  type="vertical"
                  style="height: 60px; margin: 0 25px 0 35px"
                />

                <a-avatar
                  :size="64"
                  src="https://img0.baidu.com/it/u=4275172878,2244226890&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
                  style="margin-left: 25px"
                />
                <a-avatar
                  :size="64"
                  src="https://img0.baidu.com/it/u=4275172878,2244226890&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
                  style="margin-left: 25px"
                />
                <a-avatar
                  :size="64"
                  src="https://img0.baidu.com/it/u=4275172878,2244226890&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
                  style="margin-left: 25px"
                />
              </div>
            </div>
          </a-card>
          <a-card
            title="新闻活动"
            style="width: 49%; margin-right: 1%; text-align: left"
          >
            <a slot="extra" href="javascript:;" @click="goToFun('./zwhd')"
              >更多</a
            >
            <p @click="goToFun('./zwhd')">
              <a>系统能力活动新闻</a>
              <a-divider type="vertical" /> <a>教育政策类新闻</a>
            </p>
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
              "
            >
              <img
                src="../assets/xw-001.jpg"
                style="width: 20%; margin-left: 20px"
              />
              <p style="width: 70%">
                无惧挑战：2022全国大学生计算机系统能力大赛操作系统设计赛OS功能挑战赛一等奖赛队获奖感言
              </p>
            </div>
            <ul>
              <li>
                第二届“龙芯杯”全国大学生计算机系统能力培养大赛总决赛胜利闭幕
              </li>
              <li>2018中国核心技术创新发展峰会于南京成功举办</li>
              <li>2018年“计算机体系结构”课程教导班火爆开班</li>
            </ul>
          </a-card>
          <a-card title="组织结构" style="width: 50%; text-align: left">
            <a slot="extra" href="javascript:;" @click="goToFun('./zjg')"
              >更多</a
            >
            <p @click="goToFun('./zjg')">
              <a>组织结构1</a>
              <a-divider type="vertical" /> <a>组织结构2</a>
            </p>
            <ul class="ul-li">
              <li>百舸争流，鹰击长空，第四届“龙芯杯”圆满落幕</li>
              <li>2022全国大学生计算机系统能力大赛 编译系统设计赛章程</li>
              <li>
                2021全国大学生计算机系统能力大赛操作系统设计大赛
                决赛入围名单暨优胜奖获得者
              </li>
              <li>2021年全国大学生计算机系统能力培养大赛 报名启动</li>
            </ul>
          </a-card> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import heade from "../components/heade.vue";
export default {
  data() {
    return {
      listAry: [],
      listCon: [],
      sblist:[],
      closeValue:true
      // listCon:{
      //   '关于系统能力':{
      //     list: [],
      //     titleAry:[]
      //   },
      //   '系统能力大赛':{
      //     list: [],
      //     titleAry:[]
      //   },
      //   '系统能力培养活动':{
      //     list: [],
      //     titleAry:[]
      //   },
      //   '教学资源':{
      //     list: [],
      //     titleAry:[]
      //   },  '新闻活动':{
      //     list: [],
      //     titleAry:[]
      //   }

      // },
    };
  },
  components: {},

  mounted() {
    this.init();
    this.sblistFun();
  },
  methods: {
    goUrl(url,sort,index,title){
    
      if(url){
       let path=window.location.protocol+'//'+url;
       window.open(path)
      }
      else{
        this.$router.push({ path: "./about", query: { id: index } });
      window.localStorage.setItem('xtnlds-anjing-ej-tab',sort)
      window.localStorage.setItem("xtnlds-anjing", title);
      }

     
    },
    closeFun(){
      this.closeValue = false
    },
    sblistFun(){
      let data = {
        op: "sblist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
           _this.sblist = response.data.data
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    init() {
      let data = {
        op: "modules",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.listAry = response.data.data;
            // 把导航保存下来
            window.localStorage.setItem(
              "xx-anjing-aryData",
              JSON.stringify(_this.listAry)
            );
            _this.initContent();
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    initContent() {
      let data = {
        op: "indexlist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.listCon = response.data.data;
            // for(let i=0;i<_this.listAry.length;i++){
            //   for( let k in  _this.listCon){
            //     if(k == _this.listAry[i].title){
            //       _this.listCon[k].title = _this.listAry[i].title;

            //     }

            //   }

            // }
            console.log(_this.listCon);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    toType(sort, title, index) {
      this.$router.push({ path: "./about", query: { id: index } });
      window.localStorage.setItem("xtnlds-anjing-index", index);
      window.localStorage.setItem("xtnlds-anjing", title);
      console.log(sort);
      window.localStorage.setItem("xtnlds-anjing-ej-tab", sort);
      window.localStorage.setItem("xtnlds-anjing-rel", "rel");
    },
    goToFun(url, index, title) {
      window.localStorage.setItem("xtnlds-anjing-index", index);
      window.localStorage.setItem("xtnlds-anjing", title);

      this.$router.push({ path: url });
      window.localStorage.setItem("xtnlds-anjing-rel", "rel");
    },
  },
};
</script>
<style scoped>
.right-fixed{
  position: fixed;right:10px;top:350px;z-index: 100;
  text-align: left;
}
.right-fixed /deep/.ant-card-head{
  background: #af0000;
    color: #fff;

}
.right-fixed p{
  border-bottom:1px solid #f1f1f1;
  padding-bottom:5px;
}
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  /* height: 160px;
  line-height: 160px;
  background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
/deep/.ant-avatar {
  width: 60%;
  height: 100%;
  border-radius: 0;
}
/deep/.ant-carousel .slick-slide {
  //background: #0c1628;
  height: auto;
}
.ul-li {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ul-li li{
  margin-bottom:10px;
  font-weight: 400;
}
/deep/.ant-card-head {
  background: #dff1f9;
}
.footer {
  background: #eee;
  padding: 30px 5%;
}
/deep/.ant-divider {
  background: #878787;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  /* height: 160px; */
  /* line-height: 160px; */
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel .slick-slide img {
  width:100%
}
.a /deep/.ant-card-head-title{
  font-weight: bold;
}

</style>