<template>
    <a-carousel autoplay style="padding: 15px 5%;">
      <div><img src="https://eval.t.educg.com/img/bianyidasai001.32f13557.jpeg" width="50%"/></div>
      <div><h3>2</h3></div>
      <div><h3>3</h3></div>
      <div><h3>4</h3></div>
    </a-carousel>
  </template>
  <script>
  export default {};
  </script>
  <style scoped>
  /* For demo */
  .ant-carousel >>> .slick-slide {
    text-align: center;
    /* height: 160px; */
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  
  .ant-carousel >>> .slick-slide h3 {
    color: #fff;
  }
  </style>
  